<template>
  <b-modal
    id="modal-add-rekening"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <ModalHeader
        :left-text="'Kembali'"
        :middle-text="'Informasi Rekening Bank'"
        :left-function="directPage"
        :is-rigt-column="true"
        :right-function="editUuid ? updateItem : createItem"
        :right-text="'Simpan'"
      />
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="5"
          >
            <b-row>
              <b-col
                v-if="logo_url"
                cols="12"
                class="text-center mb-2"
              >
                <b-img
                  class="border border-8"
                  width="100"
                  height="72"
                  :src="logo_url"
                />
              </b-col>
              <!-- Nama Bank -->
              <b-col
                cols="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-bank">Nama Bank <span class="text-danger">*</span></label>
                <b-form-group>
                  <v-select
                    v-model="formPayload.bank_uuid"
                    label="title"
                    :options="options"
                    placeholder="Pilih Bank"
                    :reduce="option => option.label"
                    :class="Array.isArray(messages.bank_uuid) ? 'error-validation' : ''"
                  />
                  <small
                    v-if="messages.bank_uuid && Array.isArray(messages.bank_uuid) && messages.bank_uuid.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.bank_uuid.join(', ') }}</small>
                </b-form-group>
              </b-col>
              <!-- Nama Pemilik -->
              <b-col
                cols="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama-pemilik">Nama Pemilik <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nama-pemilik"
                    v-model="formPayload.owner_name"
                    type="text"
                    placeholder="Nama Pemilik"
                    class="custom__input"
                    :class="Array.isArray(messages.owner_name) ? 'error-validation' : ''"
                  />
                  <small
                    v-if="messages.owner_name && Array.isArray(messages.owner_name) && messages.owner_name.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.owner_name.join(', ') }}</small>
                </b-form-group>
              </b-col>
              <!-- Nomor Rekening -->
              <b-col
                cols="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nomor-rekening">Nomor Rekening <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nomor-rekening"
                    v-model="formPayload.account_number"
                    type="text"
                    placeholder="Nomor Rekening"
                    class="custom__input"
                    :class="Array.isArray(messages.account_number) ? 'error-validation' : ''"
                  />
                  <small
                    v-if="messages.account_number && Array.isArray(messages.account_number) && messages.account_number.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.account_number.join(', ') }}</small>
                </b-form-group>
              </b-col>
              <!-- Jenis Rekening -->
              <b-col
                cols="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-bank">Jenis Rekening <span class="text-danger">*</span></label>
                <b-form-group>
                  <v-select
                    v-model="formPayload.account_type"
                    label="title"
                    :options="optionsRekening"
                    :class="Array.isArray(messages.account_type) ? 'error-validation' : ''"
                  />
                  <small
                    v-if="messages.account_type && Array.isArray(messages.account_type) && messages.account_type.length > 0"
                    class="text-error fw-bold-500 size12"
                  >{{ messages.account_type.join(', ') }}</small>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BFormGroup, BFormInput, BImg,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ModalHeader from '@/components/ModalHeader.vue'

import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    vSelect,
    BImg,
    BFormGroup,
    BFormInput,
    BContainer,
    ModalHeader,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    cleanUpForm: {
      type: Function,
    },
    bank: {
      type: Object,
    },
    getDataBankAccounts: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedRekening: '',
      optionsRekening: ['Tabungan', 'Giro'],
      optionsSelect: null,
      formPayload: {
        bank_uuid: '',
        owner_name: '',
        account_number: '',
        account_type: '',
      },
      editUuid: null,
      messages: '',
      options: [],
      logo_url: '',
    }
  },
  watch: {
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      // console.log(value)
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
    'formPayload.bank_uuid': function (value) {
      this.getDataBank(value)
      // console.log(value)
    },
  },
  mounted() {
    this.getDataBank()
  },
  methods: {
    async getDataBank(id) {
      await this.$store.dispatch('bank/getData', {
        uuid: `/${id || ''}`,
        params: '',
      })
        .then(result => {
          if (id) {
            this.logo_url = result.data.data.logo_url
          } else {
            result.data.data.data.forEach(element => {
              this.options.push({
                label: element.uuid,
                title: element.name,
              })
            })
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    createItem() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin membuat Rekening baru?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.$route.name === 'customer.index') {
            this.formPayload.supplier_uuid = this.$store.state.supplier.editId
          }
          const form = this.preparePayload()
          this.isLoading = true
          this.$store.dispatch('bankAccounts/postData', {
            query: '',
            payload: form,
          })
            .then(() => {
              this.getDataBankAccounts()
              successNotification(this, 'Success', 'Akun bank berhasil di buat')
              this.cleanUpForm()
              this.$bvModal.hide('modal-add-rekening')
              this.isLoading = false
            })
            .catch(error => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false
            })
        }
      });
    },
    updateItem() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin mengubah data Rekening ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // User confirmed, proceed with akun bank update
          if (this.$route.name === 'customer.index') {
            this.formPayload.supplier_uuid = this.$store.state.supplier.editId
          }
          const form = this.preparePayload()
          this.isLoading = true
          form.append('_method', 'PATCH')

          this.$store.dispatch('bankAccounts/postData', {
            query: `/${this.editUuid}`,
            payload: form,
          })
            .then(() => {
              this.getDataBankAccounts()
              successNotification(this, 'Success', 'Akun bank berhasil diubah!')
              this.cleanUpForm()
              this.isLoading = false
              this.$bvModal.hide('modal-add-rekening')
            }).catch(error => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false
            })
        }
      });
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
    directPage() {
      this.cleanUpForm()
      this.messages = ''
      this.logo_url = ''
      this.options = []
      this.$bvModal.hide('modal-add-rekening')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
