<template>
  <div class="section">
    <ModalHeader
      :left-text="'Kembali'"
      :middle-text="
        $store.state.customer.editId == null
          ? 'Tambah Pelanggan'
          : 'Edit Pelanggan'
      "
      :left-function="directPage"
      :is-rigt-column="true"
      :right-function="
        $store.state.customer.editId == null ? createItem : updateItem
      "
      :right-text="'Simpan'"
    />
    <b-container class="mt-5 pt-5">
      <b-row class="justify-content-center h-100 bg-white">
        <b-col cols="12" md="7">
          <b-row>
            <!-- Pilih Tipe Pelanggan -->
            <b-col cols="12" class="mb-2 custom__form--input">
              <label
                >Pilih Tipe Pelanggan <span class="text-danger">*</span></label
              >
              <div class="d-flex">
                <b-button
                  v-for="(item, index) in listTypeCustomer"
                  :id="`button--type__pelanggan${index}`"
                  :key="`type-customer-${index}`"
                  class="btn-type py-1"
                  :class="{ active: item.includes(formPayload.customer_type) }"
                  @click="formPayload.customer_type = item"
                >
                  <feather-icon
                    v-if="item.includes(formPayload.customer_type)"
                    icon="CheckCircleIcon"
                    class="text-primary mr-25"
                  />
                  {{ item }}
                </b-button>
              </div>
              <small
                v-if="
                  messages.customer_type &&
                  Array.isArray(messages.customer_type) &&
                  messages.customer_type.length > 0
                "
                class="text-danger"
                >{{ messages.customer_type.join(", ") }}</small
              >
            </b-col>
            <!-- Email -->
            <b-col cols="12" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-email">Email</label>
                <b-form-input
                  id="v-email"
                  v-model="formPayload.email"
                  type="email"
                  placeholder="Email"
                  class="custom__input"
                />
                <small
                  v-if="
                    messages.email &&
                    Array.isArray(messages.email) &&
                    messages.email.length > 0
                  "
                  class="text-danger"
                  >{{ messages.email.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- Nama -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-nama"
                  >Nama <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="v-nama"
                  v-model="formPayload.name"
                  type="text"
                  placeholder="Nama"
                  class="custom__input"
                />
                <small
                  v-if="
                    messages.name &&
                    Array.isArray(messages.name) &&
                    messages.name.length > 0
                  "
                  class="text-danger"
                  >{{ messages.name.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- No. Telepon -->
            <!-- <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-telp">No. Telepon <span class="text-danger">*</span></label>
                <b-form-input
                  id="v-telp"
                  v-model="formPayload.phone_number"
                  type="number"
                  placeholder="Telp"
                  class="custom__input"
                  @input="handlePhoneNumberInput"
                />
                <small
                  v-if="messages.phone_number && Array.isArray(messages.phone_number) && messages.phone_number.length > 0"
                  class="text-danger"
                >{{ messages.phone_number.join(', ') }}</small>
              </b-form-group>
            </b-col> -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <label for="v-telp"
                >No. Telepon <span class="text-danger">*</span></label
              >
              <b-row>
                <b-col cols="5" class="pr-0">
                  <v-select
                    v-model="formPayload.selectedCountry"
                    :label="currentLabel"
                    :options="countryList"
                    :clearable="false"
                    @keyup.stop.native="searchCountry"
                  >
                    <template
                      #selected-option="{ country_flag, dial_code_preview }"
                    >
                      <div class="d-flex align-items-center" style="gap: 6px">
                        <img
                          :src="country_flag"
                          :alt="dial_code_preview"
                          style="width: 24px; height: 24px"
                        />
                        <p class="size12 mb-0">({{ dial_code_preview }})</p>
                      </div>
                    </template>
                    <template #option="{ country_flag, dial_code_preview }">
                      <div class="d-flex align-items-center" style="gap: 8px">
                        <img
                          :src="country_flag"
                          :alt="dial_code_preview"
                          style="width: 24px; height: 24px"
                        />
                        <p class="mb-0">{{ dial_code_preview }}</p>
                      </div>
                    </template>
                  </v-select>
                </b-col>
                <b-col cols="7">
                  <b-form-group>
                    <b-form-input
                      id="v-telp"
                      v-model="formattedPhone"
                      type="tel"
                      placeholder="81257152456"
                      class="custom__input"
                    />
                    <small
                      v-if="
                        messages.phone_number &&
                        Array.isArray(messages.phone_number) &&
                        messages.phone_number.length > 0
                      "
                      class="text-danger"
                      >{{ messages.phone_number.join(", ") }}</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- Nama Alias -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-alias-name">Nama Alias</label>
                <b-form-input
                  id="v-alias-name"
                  v-model="formPayload.alias_name"
                  type="text"
                  placeholder="Nama Alias"
                  class="custom__input"
                />
                <small
                  v-if="
                    messages.alias_name &&
                    Array.isArray(messages.alias_name) &&
                    messages.alias_name.length > 0
                  "
                  class="text-danger"
                  >{{ messages.alias_name.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- Nama Kontak -->
            <b-col cols="12" md="6" class="mb-2 custom__form--input">
              <b-form-group
                label="Nama Kontak (Optional)"
                label-for="v-contact"
              >
                <b-form-input
                  id="v-contact"
                  v-model="formPayload.contact_name"
                  type="text"
                  placeholder="Nama Kontak"
                  class="custom__input"
                />
              </b-form-group>
            </b-col>
            <!-- Alamat -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input">
              <!-- <label>Alamat</label>
              <QuilEditor :payload="formPayload.address" @contentText="getContentText" /> -->
              <b-form-group label="Alamat" label-for="v-address" />
              <b-form-textarea
                id="v-address"
                v-model="formPayload.address"
                rows="3"
                placeholder="Alamat"
                class="custom__textarea"
                maxlength="100"
              />
              <small class="size12 text-dark text-darken-4 font-italic">
                *Maksimal 100 Karakter
              </small>
            </b-col>
            <!-- Term of Payment -->
            <b-col cols="12" class="mb-2 custom__form--input">
              <b-form-group>
                <label for="v-top"
                  >Jangka Waktu Pembayaran
                  <span class="text-danger">*</span></label
                >
                <select
                  v-model="formPayload.terms_of_payment"
                  class="custom-select"
                >
                  <option value="Cash">Tunai</option>
                  <option value="TOP">Tempo</option>
                </select>
                <!-- <v-select
                  v-model="formPayload.terms_of_payment"
                  label="title"
                  :options="topOption"
                /> -->
                <small
                  v-if="
                    messages.terms_of_payment &&
                    Array.isArray(messages.terms_of_payment) &&
                    messages.terms_of_payment.length > 0
                  "
                  class="text-danger"
                  >{{ messages.terms_of_payment.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- Hari -->
            <b-col
              v-if="formPayload.terms_of_payment == 'TOP'"
              cols="12"
              md="6"
              class="mb-2 custom__form--input"
            >
              <b-form-group label="Hari" label-for="v-day">
                <b-form-input
                  id="v-day"
                  v-model="formPayload.terms_of_payment_day"
                  type="number"
                  class="custom__input"
                  placeholder="--"
                />
                <small
                  v-if="
                    messages.terms_of_payment_day &&
                    Array.isArray(messages.terms_of_payment_day) &&
                    messages.terms_of_payment_day.length > 0
                  "
                  class="text-danger"
                  >{{ messages.terms_of_payment_day.join(", ") }}</small
                >
              </b-form-group>
            </b-col>
            <!-- Nomor NPWP -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input">
              <b-form-group label="Nomor NPWP" label-for="v-npwp">
                <input
                  id="v-npwp"
                  type="text"
                  class="form-control custom__input"
                  @change="maskingInputNpwp"
                  @input="maskingInputNpwp"
                />
              </b-form-group>
            </b-col>
            <!-- Upload NPWP -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input">
              <div class="d-flex">
                <div class="mr-2">
                  <label for="">Upload NPWP</label>
                  <UploadPhoto
                    v-if="!photo_url_npwp"
                    :label-for="'upload_photo_npwp'"
                    :style-name="'width:212px;height:141px;'"
                    :photo-url-parent="photo_url_npwp"
                    @photo="getPhoto"
                  />
                  <div v-else>
                    <div
                      class="box__upload--photo mx-auto"
                      :style="`background-image: url(${photo_url_npwp});`"
                      @click="photo_url_npwp = ''"
                    >
                      <b-avatar class="floating__close--button">
                        <feather-icon class="text-danger" icon="XIcon" />
                      </b-avatar>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-column align-items-start justify-content-center w-50"
                >
                  <b-button class="bg-white mb-1 p-0">
                    <label
                      class="mb-0 px-2 py-1"
                      for="upload_photo_npwp"
                      style="cursor: pointer"
                      @click="photo_url_npwp = null"
                    >
                      {{
                        is_upload_npwp == false ? "Upload NPWP" : "Ganti NPWP"
                      }}
                    </label>
                  </b-button>
                  <small
                    >Maksimal ukuran gambar 12mb dan foto harus terlihat jelas
                    atau tidak blur</small
                  >
                </div>
              </div>
            </b-col>
            <!-- Upload KTP -->
            <b-col cols="12" md="12" class="mb-2 custom__form--input">
              <b-form-group label="Nomor KTP" label-for="v-ktp">
                <b-form-input
                  id="v-ktp"
                  v-model="formPayload.id_card_number"
                  type="number"
                  placeholder="Nomor KTP"
                  class="custom__input"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" class="mb-2 custom__form--input">
              <div class="d-flex">
                <div class="mr-2">
                  <label for="">Upload KTP</label>
                  <UploadPhoto
                    v-if="!photo_url_ktp"
                    :label-for="'upload_photo_ktp'"
                    :style-name="'width:212px;height:141px;'"
                    :photo-url-parent="photo_url_ktp"
                    @photo="getPhoto"
                  />
                  <div v-else>
                    <div
                      class="box__upload--photo mx-auto"
                      :style="`background-image: url(${photo_url_ktp});`"
                      @click="photo_url_ktp = ''"
                    >
                      <b-avatar class="floating__close--button">
                        <feather-icon class="text-danger" icon="XIcon" />
                      </b-avatar>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-column align-items-start justify-content-center w-50"
                >
                  <b-button class="bg-white mb-1 p-0">
                    <label
                      class="mb-0 px-2 py-1"
                      for="upload_photo_ktp"
                      style="cursor: pointer"
                      @click="photo_url_ktp = null"
                    >
                      {{ is_upload_ktp == false ? "Upload KTP" : "Ganti KTP" }}
                    </label>
                  </b-button>
                  <small
                    >Maksimal ukuran gambar 12mb dan foto harus terlihat jelas
                    atau tidak blur</small
                  >
                </div>
              </div>
            </b-col>
            <Riwayat v-if="$store.state.customer.editId !== null" />
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BAvatar,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import { mapActions } from "vuex";

import Riwayat from "@/components/Customer/RiwayatCustomer.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import UploadPhoto from "@/components/UploadPhoto.vue";
import QuilEditor from "@/components/QuilEditor.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    UploadPhoto,
    BAvatar,
    ModalHeader,
    QuilEditor,
    Riwayat,
    vSelect,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    getData: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      type: "Toko",
      listTypeCustomer: [
        "Toko",
        "Perusahaan",
        "Perorangan Project",
        "Perorangan Ritel",
      ],
      is_upload_ktp: false,
      is_upload_npwp: false,
      photo_url_ktp: null,
      photo_url_npwp: null,
      topOption: ["Cash", "TOP"],
      messages: "",
      isLoading: false,
      formPayload: {
        customer_type: "Toko",
        email: "",
        name: "",
        alias_name: "",
        phone_number: "",
        phone_number_country: "",
        contact_name: "",
        address: "",
        terms_of_payment: "",
        terms_of_payment_day: "",
        tax_id_photo: "",
        tax_id_number: "",
        id_card_photo: "",
        id_card_number: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      searchQuery: "",
      countryList: [],
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQuery)
        ? "dial_code_value"
        : "country_name";
    },
    dialCode() {
      const selectedCountry = this.countryList.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "";
    },
    formattedPhone: {
      get() {
        if (!this.formPayload.phone_number) {
          return "";
        }
        const regex = new RegExp(`^${this.dialCode}`);
        return this.formPayload.phone_number.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.phone_number = this.dialCode + value;
      },
    },
  },
  watch: {
    "formPayload.selectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.phone_number.startsWith(oldDialCode)) {
        this.formPayload.phone_number =
          newDialCode + this.formPayload.phone_number.replace(oldDialCode, "");
      }
    },
  },
  created() {
    this.formPayload = this.$store.state.customer.formPayload;
    this.photo_url_npwp = this.$store.state.customer.npwp_url;
    this.photo_url_ktp = this.$store.state.customer.ktp_url;
  },
  mounted() {
    if (this.formPayload.tax_id_number) {
      setTimeout(() => {
        const npwp = document.getElementById("v-npwp");
        npwp.value = this.maskNumber(this.formPayload.tax_id_number).replaceAll(
          "#",
          ""
        );
      }, 1000);
    }
    this.getCountry();
  },
  destroyed() {
    this.formPayload.customer_type = "Toko";
  },
  methods: {
    ...mapActions("customer", ["postData"]),
    maskingInputNpwp() {
      const npwp = document.getElementById("v-npwp");
      npwp.value = this.maskNumber(npwp.value).replaceAll("#", "");
      this.formPayload.tax_id_number = npwp.value.replaceAll(" ", "");
    },
    handlePhoneNumberInput(value) {
      if (!value) {
        this.formPayload.phone_number = "62";
      }
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          this.countryList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    maskNumber(number) {
      // Remove all non-digit characters from the input number
      var digitsOnly = number.replace(/\D/g, "");

      // Determine the number of groups
      var numGroups = Math.ceil(digitsOnly.length / 4);

      // Generate the mask
      var mask = "";
      for (var i = 0; i < numGroups; i++) {
        mask += "#### ";
      }
      mask = mask.trim();

      // Apply the mask to the number
      var maskedNumber = "";
      var digitIndex = 0;
      for (var j = 0; j < mask.length; j++) {
        var char = mask.charAt(j);
        if (char === "#" && digitIndex < digitsOnly.length) {
          maskedNumber += digitsOnly.charAt(digitIndex);
          digitIndex++;
          if (digitIndex % 4 === 0 && digitIndex !== digitsOnly.length) {
            maskedNumber += " ";
          }
        } else {
          maskedNumber += char;
        }
      }

      return maskedNumber;
    },
    getContentText(val) {
      this.formPayload.address = val;
    },
    getPhoto(value) {
      if (value.label === "upload_photo_npwp") {
        this.formPayload.tax_id_photo = value.photo;
        this.is_upload_npwp = true;
      } else {
        this.formPayload.id_card_photo = value.photo;
        this.is_upload_ktp = true;
      }
    },
    clearItem() {
      this.$store.commit("customer/setNpwpUrl", null);
      this.$store.commit("customer/setKtpUrl", null);
      this.$store.commit("customer/setEditId", null);
      this.$store.commit(
        "customer/setFormPayload",
        (this.formPayload = {
          customer_type: "",
          email: "",
          name: "",
          alias_name: "",
          phone_number: "",
          phone_number_country: "",
          contact_name: "",
          address: "",
          terms_of_payment: "",
          terms_of_payment_day: "",
          tax_id_number: "",
          id_card_number: "",
          selectedCountry: {
            country_code: "ID",
            country_flag: "https://flagsapi.com/ID/shiny/64.png",
            country_name: "Indonesia",
            dial_code_preview: "+62",
            dial_code_value: "62",
          },
        })
      );
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin membuat Pelanggan baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (this.formPayload.terms_of_payment !== "TOP") {
            delete this.formPayload.terms_of_payment_day;
          }
          const form = this.preparePayload();
          this.isLoading = true;
          this.postData({
            query: "",
            payload: form,
          })
            .then(() => {
              this.getData();
              successNotification(
                this,
                "Success",
                "Pelanggan berhasil di buat"
              );
              this.clearItem();
              this.$bvModal.hide("modal-customer");
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
              }
              this.isLoading = false;
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin mengubah data Pelanggan ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("customer/postData", {
              query: `/${this.$store.state.customer.editId}`,
              payload: form,
            })
            .then(() => {
              this.getData();
              successNotification(
                this,
                "Success",
                "Pelanggan berhasil di ubah!"
              );
              this.clearItem();
              this.isLoading = false;
              this.$bvModal.hide("modal-customer");
            })
            .catch((error) => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages;
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false;
            });
        }
      });
    },
    preparePayload() {
      this.formPayload.selectedCountry != null
        ? (this.formPayload.phone_number_country =
            this.formPayload.selectedCountry.country_code)
        : "";
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key !== "phone_number" && key != "selectedCountry") {
            form.append(key, this.formPayload[key]);
          } else {
            const digit1 = Number(String(this.formPayload[key]).charAt(0));
            form.append(
              key,
              digit1 === 0
                ? `62${this.formPayload[key].substring(1)}`
                : this.formPayload[key]
            );
          }
        }
      }
      return form;
    },
    directPage() {
      this.clearItem();
      this.$bvModal.hide("modal-customer");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

#app {
  background-color: #fff;
}

.bg-white.btn-secondary:focus {
  background-color: transparent !important;
}

.btn-type {
  background-color: #eff1f5 !important;
  border: 1px solid #e4e5ec !important;
  border-radius: 8px;
  margin-right: 15px;
  color: $dark !important;

  &.active {
    background-color: #ffffff !important;
    border: 1px solid #89a7e0 !important;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
    color: #2e5aac !important;
  }
}
</style>
