<template>
  <li
    class="timeline-item"
    :class="[`timeline-variant-${variant}`, fillBorder ? `timeline-item-fill-border-${variant}` : null]"
  >
    <div
      v-if="!icon"
      class="timeline-item-point"
    />
    <div
      v-else
      class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
    >
      <feather-icon :icon="icon" />
    </div>

    <slot>
      <div class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0">
        <h6 v-text="title" class="mb-0 size14" style="margin-right: 5px;" />
        <h6
          class="timeline-item-time text-nowrap text-dark text-darken-5 fw-bold-500 size14"
          v-text="by"
        />
      </div>
      <!-- Buying Price -->
      <div class="d-flex align-items-center" v-if="buying_price">
        <b-img
          v-if="buying_price.price_type == 'down'"
          :src="require(`@/assets/images/icons/Icon-order-arrow-up.svg`)"
        />
        <b-img
          v-else-if="buying_price.price_type == 'up'"
          :src="require(`@/assets/images/icons/Icon-order-arrow-down.svg`)"
        />
        <h6 class="mb-0 size12">Harga Modal</h6>
        <h4
          class="text-dark fw-bold-600 mb-0"
          style="margin-left: 5px;"
        >
          {{ buying_price.from | formatAmount }}
        </h4>
        <feather-icon
          v-if="buying_price.to || buying_price.from"
          size="18"
          class="text-dark fw-bold-600"
          style="margin-left:5px;margin-right:5px;"
          icon="ArrowRightIcon"
        />
        <h4
          class="text-dark fw-bold-600 mb-0"
        >
          {{ buying_price.to | formatAmount }}
        </h4>
      </div>

      <!-- Minimum Price -->
      <div class="d-flex align-items-center" v-if="minimum_price">
        <b-img
          v-if="minimum_price.price_type == 'down'"
          :src="require(`@/assets/images/icons/Icon-order-arrow-up.svg`)"
        />
        <b-img
          v-else-if="minimum_price.price_type == 'up'"
          :src="require(`@/assets/images/icons/Icon-order-arrow-down.svg`)"
        />
        <h6 class="mb-0 size12">Harga Jual Minimum</h6>
        <h4
          class="text-dark fw-bold-600 mb-0"
          style="margin-left: 5px;"
        >
          {{ minimum_price.from | formatAmount }}
        </h4>
        <feather-icon
          v-if="minimum_price.to || minimum_price.from"
          size="18"
          class="text-dark fw-bold-600"
          style="margin-left:5px;margin-right:5px;"
          icon="ArrowRightIcon"
        />
        <h4
          class="text-dark fw-bold-600 mb-0"
        >
          {{ minimum_price.to | formatAmount }}
        </h4>
      </div>
      
      <!-- Price -->
      <div class="d-flex align-items-center" v-if="image">
        <b-img
          v-if="image == 'down'"
          :src="require(`@/assets/images/icons/Icon-order-arrow-up.svg`)"
        />
        <b-img
          v-else-if="image == 'up'"
          :src="require(`@/assets/images/icons/Icon-order-arrow-down.svg`)"
        />
        <h6 class="mb-0 size12">Harga Jual Standard</h6>
        <h4
          class="text-dark fw-bold-600 mb-0"
          style="margin-left: 5px;" 
        >
          {{ priceFrom | formatAmount }}
        </h4>
        <feather-icon
          v-if="priceTo || priceFrom"
          size="18"
          class="text-dark fw-bold-600"
          style="margin-left:5px;margin-right:5px;"
          icon="ArrowRightIcon"
        />
        <h4
          class="text-dark fw-bold-600 mb-0"
        >
          {{ priceTo | formatAmount }}
        </h4>
      </div>
      
      <p
        class="mb-0"
        v-text="subtitle"
      />
    </slot>
  </li>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    by: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: null,
    },
    priceFrom: {
      type: '',
      default: null,
    },
    priceTo: {
      type: '',
      default: null,
    },
    buying_price: {
      type: ''
    },
    minimum_price: {
      type: ''
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

</style>
