<template>
  <div>
    <quill-editor
      v-model="content"
      :options="editorOption"
      >
      <div
        id="toolbar"
        slot="toolbar"
      >
      </div>
    </quill-editor>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    props: {
        payload: {
            type: String,
        }
    },
    components: {
        quillEditor,
    },
    data: () => ({
        editorOption: {
            modules: {
                toolbar: '#toolbar',
            },
        },
        content: '',
    }),
    watch: {
        payload(val) {
            this.content = val
        },
        content(val) {
            this.$emit('contentText', val)
        }
    },
    mounted() {
        this.content = this.payload
    },
    methods: {
    },
}
</script>

<style>
.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border: unset;
}
.ql-toolbar.ql-snow {
  background: #F1F2F5;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 0;
  padding: 0;
}
.ql-container{
  background: #F1F2F5;
  height: 110px;
  border-radius: 8px;
  /* border-bottom-left-radius: 8px; */
}
</style>
