var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('ModalHeader',{attrs:{"left-text":'Kembali',"middle-text":_vm.$store.state.supplier.editId == null ? 'Tambah Pemasok' : 'Edit Pemasok',"left-function":_vm.directPage,"is-rigt-column":true,"right-function":_vm.$store.state.supplier.editId == null ? _vm.createItem : _vm.updateItem,"right-text":'Simpan'}}),_c('b-container',{staticClass:"mt-5 pt-5"},[_c('b-row',{staticClass:"justify-content-center h-100 bg-white"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-supplier"}},[_vm._v("Nama Pemasok "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-supplier","type":"text","placeholder":"Nama Pemasok"},model:{value:(_vm.formPayload.name),callback:function ($$v) {_vm.$set(_vm.formPayload, "name", $$v)},expression:"formPayload.name"}}),(
                  _vm.messages.name &&
                  Array.isArray(_vm.messages.name) &&
                  _vm.messages.name.length > 0
                )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.name.join(", ")))]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"v-telp-kantor"}},[_vm._v("No. Telepon "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.currentLabel,"options":_vm.countryList,"clearable":false},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.searchCountry($event)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                var country_flag = ref.country_flag;
                var dial_code_preview = ref.dial_code_preview;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"6px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":country_flag,"alt":dial_code_preview}}),_c('p',{staticClass:"size12 mb-0"},[_vm._v("("+_vm._s(dial_code_preview)+")")])])]}},{key:"option",fn:function(ref){
                var country_flag = ref.country_flag;
                var dial_code_preview = ref.dial_code_preview;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":country_flag,"alt":dial_code_preview}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(dial_code_preview))])])]}}]),model:{value:(_vm.formPayload.selectedCountry),callback:function ($$v) {_vm.$set(_vm.formPayload, "selectedCountry", $$v)},expression:"formPayload.selectedCountry"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-telp-kantor","type":"tel","placeholder":"81257152456"},model:{value:(_vm.formattedPhoneKantor),callback:function ($$v) {_vm.formattedPhoneKantor=$$v},expression:"formattedPhoneKantor"}}),(
                      _vm.messages.phone_number &&
                      Array.isArray(_vm.messages.phone_number) &&
                      _vm.messages.phone_number.length > 0
                    )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.phone_number.join(", ")))]):_vm._e()],1)],1)],1)],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-npwp"}},[_vm._v("No. NPWP "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{staticClass:"form-control custom__input",attrs:{"id":"v-npwp","type":"text"},on:{"change":_vm.maskingInputNpwp,"input":_vm.maskingInputNpwp}}),(
                  _vm.messages.tax_number &&
                  Array.isArray(_vm.messages.tax_number) &&
                  _vm.messages.tax_number.length > 0
                )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.tax_number.join(", ")))]):_vm._e()])],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-address"}},[_vm._v("Alamat "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('quil-editor',{attrs:{"payload":_vm.formPayload.address},on:{"contentText":_vm.getContentText}}),(
                _vm.messages.address &&
                Array.isArray(_vm.messages.address) &&
                _vm.messages.address.length > 0
              )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.address.join(", ")))]):_vm._e()],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"v-nama-sales"}},[_vm._v("Nama Sales "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-nama-sales","type":"text","placeholder":"Nama Sales"},model:{value:(_vm.formPayload.sales_name),callback:function ($$v) {_vm.$set(_vm.formPayload, "sales_name", $$v)},expression:"formPayload.sales_name"}}),(
                  _vm.messages.sales_name &&
                  Array.isArray(_vm.messages.sales_name) &&
                  _vm.messages.sales_name.length > 0
                )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.sales_name.join(", ")))]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"v-telp-sales"}},[_vm._v("No. Telepon Sales "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.currentLabel,"options":_vm.countryList,"clearable":false},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.searchCountry($event)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                var country_flag = ref.country_flag;
                var dial_code_preview = ref.dial_code_preview;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"6px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":country_flag,"alt":dial_code_preview}}),_c('p',{staticClass:"size12 mb-0"},[_vm._v("("+_vm._s(dial_code_preview)+")")])])]}},{key:"option",fn:function(ref){
                var country_flag = ref.country_flag;
                var dial_code_preview = ref.dial_code_preview;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":country_flag,"alt":dial_code_preview}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(dial_code_preview))])])]}}]),model:{value:(_vm.formPayload.salesSelectedCountry),callback:function ($$v) {_vm.$set(_vm.formPayload, "salesSelectedCountry", $$v)},expression:"formPayload.salesSelectedCountry"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-telp-sales","type":"tel","placeholder":"81257152456"},model:{value:(_vm.formattedPhoneSales),callback:function ($$v) {_vm.formattedPhoneSales=$$v},expression:"formattedPhoneSales"}}),(
                      _vm.messages.phone_number &&
                      Array.isArray(_vm.messages.phone_number) &&
                      _vm.messages.phone_number.length > 0
                    )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.phone_number.join(", ")))]):_vm._e()],1)],1)],1)],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"v-telp-sales"}},[_vm._v("Pilih Tipe Pembayaran "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.listPayment),function(item,index){return _c('b-button',{key:("type-customer-" + index),staticClass:"btn-type py-1",class:{ active: item == _vm.formPayload.payment_type },on:{"click":function($event){_vm.formPayload.payment_type = item}}},[_vm._v(" "+_vm._s(item)+" ")])}),1),(
                _vm.messages.payment_type &&
                Array.isArray(_vm.messages.payment_type) &&
                _vm.messages.payment_type.length > 0
              )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.payment_type.join(", ")))]):_vm._e()]),(_vm.formPayload.payment_type == 'TOP')?_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Hari","label-for":"v-day"}},[_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-day","type":"number","placeholder":"--"},model:{value:(_vm.formPayload.terms_of_payment_day),callback:function ($$v) {_vm.$set(_vm.formPayload, "terms_of_payment_day", $$v)},expression:"formPayload.terms_of_payment_day"}}),(
                  _vm.messages.terms_of_payment_day &&
                  Array.isArray(_vm.messages.terms_of_payment_day) &&
                  _vm.messages.terms_of_payment_day.length > 0
                )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages.terms_of_payment_day.join(", ")))]):_vm._e()],1)],1):_vm._e()],1),_c('hr'),(_vm.$store.state.supplier.editId !== null)?_c('b-row',{staticClass:"pb-5"},[_c('BankAccounts',{attrs:{"banks":_vm.banks,"reload-data":_vm.getBankAccounts}})],1):_vm._e(),(_vm.$store.state.supplier.editId !== null)?_c('Riwayat'):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }