<template>
  <div class="section">
    <div
      class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between"
      style="height:82px;background-color: #f6f7fa;"
    >
      <b-button-group>
        <b-button
          v-if="checkPermission('list pelanggan')"
          class="filter--button"
          :class="{ 'active' : filter == 'Pelanggan' }"
          @click="filter = 'Pelanggan', comp = 'TableCustomer', is_edit = null"
        >
          Pelanggan
        </b-button>
        <b-button
          v-if="checkPermission('list supplier')"
          class="filter--button"
          :class="{ 'active' : filter == 'Pemasok' }"
          @click="filter = 'Pemasok', comp = 'TableSupplier', is_edit = null"
        >
          Pemasok
        </b-button>
      </b-button-group>
      <div
        class="d-flex search__export"
        style="width: unset;"
      >
        <!-- <FilterButton /> -->
        <b-button
          v-if="checkPermission('add pelanggan') && comp === 'TableCustomer'"
          variant="outline-secondary py-0 bg-secondary text-white"
          @click="addData('modal-customer')"
        >
          <span>+ Tambah Data</span>
        </b-button>
        <b-button
          v-if="checkPermission('add supplier') && comp === 'TableSupplier'"
          variant="outline-secondary py-0 bg-secondary text-white"
          @click="addData('modal-supplier')"
        >
          <span>+ Tambah Data</span>
        </b-button>

      </div>
    </div>

    <keep-alive>
      <component
        :is="comp"
        :is-edit="is_edit"
      />
    </keep-alive>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BDropdown, BDropdownItem, BImg, BPagination, BFormSelect, BModal, VBToggle, BRow, BCol,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import HeaderPage from '@/components/HeaderPage.vue'
import TableCustomer from '@/components/Customer/TabelCustomer.vue'
import TableSupplier from '@/components/Customer/TableSupplier.vue'
import FilterButton from '@/components/BaseFilter.vue'

export default {
  title() {
    return 'Pelanggan'
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    TableCustomer,
    TableSupplier,
    FilterButton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      listFilter: ['Pelanggan', 'Pemasok'],
      filter: 'Pelanggan',
      rows: [],
      comp: 'TableCustomer',
      is_edit: null,
    }
  },
  computed: {
  },
  created() {},
  mounted() {
    if (this.checkPermission('list pelanggan')) {
      this.filter = 'Pelanggan'
      this.comp = 'TableCustomer'
    } else if (this.checkPermission('list supplier')) {
      this.filter = 'Pemasok'
      this.comp = 'TableSupplier'
    }
  },
  methods: {
    addData(type) {
      this.is_edit = true
      this.$bvModal.show(type)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

</style>
