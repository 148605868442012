<template>
  <b-modal
    id="modal-customer"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <keep-alive>
      <!-- <AddCustomer
        v-if="comp == 'AddCustomer'"
        :get-data="getData"
      />
      <DetailCustomer
        v-if="comp == 'DetailCustomer'"
        :get-data="getData"
        :result="resultDetail"
        :type_component="type_component"
      /> -->
      <component
        :is="comp"
        :get-data="getData"
        :results="resultDetail"
        :type_component="type_component"
      />
    </keep-alive>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

import AddCustomer from '@/components/Customer/Modal/Part/AddCustomer.vue'
import DetailCustomer from '@/components/Customer/Modal/Part/DetailCustomer.vue'

export default {
  components: {
    BModal,
    AddCustomer,
    DetailCustomer,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    // eslint-disable-next-line vue/prop-name-casing
    is_edit: {
      type: Boolean,
      default: null,
    },
    type_component: {
      type: String,
    },
    getData: {
      type: Function,
    },
    result: {
      type: Object,
    },
  },
  data() {
    return {
      comp: 'AddCustomer',
      resultDetail: {},
    }
  },
  watch: {
    result: {
      handler(value) {
        this.resultDetail = value
      },
      deep: true,
    },
    is_edit(value) {
      if (value === true) {
        this.comp = 'AddCustomer'
      } else {
        this.comp = 'DetailCustomer'
      }
    },
  },
  mounted() {
    if (this.is_edit === true) {
      this.comp = 'AddCustomer'
    } else {
      this.comp = 'DetailCustomer'
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
